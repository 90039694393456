import React, { useEffect, useRef } from 'react';

const MatrixRain = () => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    let cols = Math.floor(window.innerWidth / 15);
    const characters = "REDMORPHPRIVACYCONTROLSECURITYredmorphprivacycontrolsecurity".split('');
    let matrix = Array.from({ length: cols }, () => 0);
    let lastDrawTime = 0;
    const frameRate = 10;

    const resizeCanvas = () => {
      cols = Math.floor(window.innerWidth / 15);
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
      matrix = Array.from({ length: cols }, () => 0);
    };

    const draw = (timestamp) => {
      if (timestamp - lastDrawTime < 1000 / frameRate) {
        requestAnimationFrame(draw);
        return;
      }

      lastDrawTime = timestamp;

      ctx.fillStyle = 'rgba(0, 0, 0, 0.05)';
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      ctx.fillStyle = '#0f0';
      ctx.font = '15px monospace';
      matrix.forEach((value, index) => {
        const char = characters[Math.floor(Math.random() * characters.length)];
        ctx.fillText(char, index * 15, value * 15);
        if (value * 15 > canvas.height && Math.random() > 0.975) {
          matrix[index] = 0;
        } else {
          matrix[index]++;
        }
      });

      requestAnimationFrame(draw);
    };

    window.addEventListener('resize', resizeCanvas);
    resizeCanvas();
    draw(0);

    return () => {
      window.removeEventListener('resize', resizeCanvas);
      ctx.clearRect(0, 0, canvas.width, canvas.height);
    };
  }, []);

  return (
    <div style={{ position: 'absolute', top: 0, left: 0 }}>
      <canvas ref={canvasRef} width={window.innerWidth} height={window.innerHeight}></canvas>
    </div>
  );
};

export default MatrixRain;
