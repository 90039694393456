import React from "react";
import { useNavigate } from "react-router-dom";

const Navbar = () => {
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      const response = await fetch("/logout");

      if (response.statusText === "OK") {
        // Save token to local storage
        localStorage.removeItem("rmxtoken");
        // Redirect to the login page
        navigate("/login");
      } else {
        console.error(
          "Error during logout: Server returned status code " + response.status
        );
      }
    } catch (error) {
      console.error("Error during logout:", error);
    }
  };

  return (
    <nav className="navbar navbar-expand-lg sticky-top navbar-light bg-light">
      <div className="container-fluid ps-2 pe-0">
        <a href="/home" className="logo">
          <img src="/static/img/horizontal logo black.svg" alt="Logo" />
        </a>
        <button
          className="navbar-toggler shadow-none ms-2"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navigation"
          aria-controls="navigation"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon mt-2">
            <span className="navbar-toggler-bar bar1"></span>
            <span className="navbar-toggler-bar bar2"></span>
            <span className="navbar-toggler-bar bar3"></span>
            <span className="navbar-toggler-bar bar4"></span>
          </span>
        </button>
        <div className="collapse navbar-collapse" id="navigation">
          <ul className="navbar-nav ms-auto">
            <li className="nav-item">
              <button
                onClick={() => navigate("/home")}
                className="nav-link me-2"
                style={{ backgroundColor: "transparent", border: "none" }}
              >
                <i className="ri-dashboard-line"></i>
                Dashboard
              </button>
            </li>
          </ul>
          <ul className="navbar-nav d-lg-block d-none">
            <li className="nav-item">
              <button
                onClick={handleLogout}
                className="btn btn-sm mb-0 me-1"
                style={{ backgroundColor: "#D03F2C", color: "#ffffff" }}
              >
                Logout
              </button>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
