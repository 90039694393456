import { VectorMap } from "@react-jvectormap/core";
import { worldMill } from "@react-jvectormap/world";
import React from "react";
import chroma from "chroma-js";
import { missingCountries } from "./Countries";

function WorldMap({ countries }) {
  // If countries is undefined or null, use an empty object instead
  countries = countries || {};

  // Ensure all countries have a count defined, defaulting to 0 if null or undefined
  Object.keys(countries).forEach((country) => {
    if (countries[country] == null) {
      countries[country] = 0;
    }
  });

  // If countries is empty, return null
  if (Object.keys(countries).length === 0) {
    return null;
  }
  const maxVisitorCount = Math.max(...Object.values(countries));
  // Create a color scale
  const colorScale = chroma
    .scale(["#f0f0f0", "#555555"])
    .mode("lab")
    .colors(Object.keys(countries).length);

  return (
    <div
      style={{
        margin: "10px auto 0",
        width: "calc(100% - 4px)",
        height: "600px",
        backgroundColor: "#fff",
        borderRadius: "8px",
        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
      }}
    >
      <VectorMap
        map={worldMill}
        containerStyle={{
          width: "100%",
          height: "100%",
        }}
        backgroundColor="#fff"
        markers={missingCountries}
        markerStyle={{
          initial: {
            fill: "red",
          },
        }}
        series={{
          regions: [
            {
              scale: colorScale,
              values: countries,
              min: 0,
              max: maxVisitorCount,
              attribute: "fill", // Set the attribute to fill for regions
            },
          ],
        }}
        regionStyle={{
          initial: {
            "stroke-width": 0.5, // Border width
            stroke: "#dad7cd", // Border color
          },
        }}
        onRegionTipShow={function reginalTip(event, label, code) {
          const visitorCount =
            countries[code] !== undefined ? countries[code] : 0;
          return label.html(`
            <div style="background-color: #fca311; border-radius: 5px; min-height: 20px; width: 125px; color: #555555; padding: 10px; box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);">
              <p style="margin: 0; font-size: 14px; font-weight: bold;">
                ${label.html()}
              </p>
              <p style="margin: 0; font-size: 12px;">
                ${visitorCount} visitors
              </p>
            </div>`);
        }}
        onMarkerTipShow={function markerTip(event, label, code) {
          return label.html(`
                  <div style="background-color: white; border-radius: 6px; min-height: 50px; width: 125px; color: black !important; padding-left: 10px">
                    <p style="color: black !important;">
                    <b>
                    ${label.html()}
                    </b>
                    </p>
                    </div>`);
        }}
        containerClassName="map-container"
      />
    </div>
  );
}

export default WorldMap;
