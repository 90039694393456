import React from "react";

const VisitorCard = ({ title, count, change, icon }) => {
  return (
    <div className="col-xl-2 col-md-4 col-sm-6 col-12">
      {/* Add some space */}
      <div style={{ marginTop: "3px" }}></div>
      <div className="card border-left-primary shadow py-2 minht100">
        <div className="card-body">
          <div className="row no-gutters">
            <div className="col-3 d-flex align-items-center justify-content-center">
              <i className={icon} style={{ fontSize: "2rem" }}></i>{" "}
              {/* Adjust the font size here */}
              {/* <i class="ri-calendar-schedule-fill"></i> */}
            </div>
            <div className="col-9 mr-2">
              <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                {title}
              </div>
              <div className="h5 mb-0 font-weight-bold text-gray-800">
                {count}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VisitorCard;
