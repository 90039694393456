// BaseComponent.jsx
import React from "react";

class BaseComponent extends React.Component {
  componentDidMount() {
    this.loadScripts();
    this.loadStylesheets();
  }

  loadScripts() {
    const scripts = [
      "/static/js/core/jquery.min.js",
      "/static/js/d3.min.js",
      "/static/js/topojson.min.js",
      "/static/js/datamaps.world.min.js",
    ];

    scripts.forEach((src) => {
      const script = document.createElement("script");
      script.src = src;
      script.crossOrigin = "anonymous";
      document.body.appendChild(script);
    });
  }

  loadStylesheets() {
    const stylesheets = [
      "/static/css/style.css",
      "/static/css/material-dashboard.css",
      "/static/css/font-awesome.min.css",
      "/static/fonts/RemixIcon/remixicon.css",
      // "/static/fonts/Orbitron/Orbitron-VariableFont_wght.ttf",
      // "/static/fonts/Open_Sans/OpenSans-VariableFont_wdth,wght.ttf",
      //   "/static/css/dataTables.bootstrap5.css",
      //   "/static/css/bootstrap.min.css",
      //   "/static/css/login.css",
      //   "/static/css/material-dashboard.css",
      //   "static/css/material-dashboard.min.css",
      //   "static/css/material-dashboard.css.map",
      //   "static/css/nucleo-icons.css",
      //   "static/css/nuclea-svg.css",
    ];

    stylesheets.forEach((href) => {
      const link = document.createElement("link");
      link.rel = "stylesheet";
      link.href = href;
      document.head.appendChild(link);
    });
  }

  render() {
    return null; // BaseComponent doesn't render anything
  }
}

export default BaseComponent;
