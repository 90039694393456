import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import MatrixRain from "./MatrixRain";
import Footer from "./Footer";
import BaseComponent from "./BaseComponent";

const LoginForm = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    document.title = "Redmorph: Admin Login";
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent default form submission

    // Trim whitespace from username and password
    const trimmedUsername = username.trim();
    const trimmedPassword = password.trim();

    try {
      const response = await fetch("/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          username: trimmedUsername,
          pwd: trimmedPassword,
        }),
      });

      const data = await response.json();

      if (response.status === 200) {
        // Check status code for success
        // Save token to local storage
        localStorage.setItem("rmxtoken", data.token);
        // console.log(
        //   "Token saved to localStorage:",
        //   localStorage.getItem("rmxtoken")
        // );

        console.log("Navigating to home page...");
        setTimeout(() => {
          // navigate("/home");
          // Navigate to the desired URL
          window.location.href = "/home";
          // console.log("Navigation completed.");
        }, 0); // Using a minimal delay of 0 milliseconds
      } else {
        setError(data.error || "Failed to login");
      }
    } catch (error) {
      console.error("Error during login:", error);
      setError("Failed to login");
    }
  };

  return (
    <React.Fragment>
      <BaseComponent />
      <MatrixRain />
      <section className="vh-100 gradient-custom" id="login-form">
        <div className="parent">
          <div className="card">
            <div className="text-center mb-3 w-100">
              <img src="/static/img/redmorph.jpg" alt="Redmorph Logo" />
              <br />
              <h4 className="my-4 card-head">Admin Login</h4>
              <form onSubmit={handleSubmit} className="mx-auto w-75">
                <div className="form-label-group mb-4">
                  <input
                    type="text"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    id="username"
                    placeholder="Username"
                    className="form-control form-control-lg"
                    style={{
                      borderRadius: "10px",
                      border: "1px solid #344767",
                      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                    }}
                  />
                </div>
                <div className="form-label-group mb-4">
                  <input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    id="password"
                    placeholder="Password"
                    className="form-control form-control-lg"
                    style={{
                      borderRadius: "10px",
                      border: "1px solid #344767",
                      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                    }}
                  />
                </div>
                <div className="text-center mb-4">
                  <button
                    type="submit"
                    className="btn btn-primary btn-lg"
                    style={{
                      backgroundColor: "#D03F2C",
                      color: "#ffffff",
                      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
                      borderRadius: "10px",
                    }}
                  >
                    Login
                  </button>
                </div>
                {error && (
                  <div className="text-center mb-2">
                    <span className="text-danger" id="error">
                      {error}
                    </span>
                  </div>
                )}
              </form>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </React.Fragment>
  );
};

export default LoginForm;
