import React, { useState, useEffect } from "react";
import axios from "axios"; // Make sure to install axios package
import Navbar from "./Navbar";
import Footer from "./Footer";
import BaseComponent from "./BaseComponent";
import VisitorCard from "./VisitorCard";
import MapCard from "./MapCard";

const Home = () => {
  const [visitorData, setVisitorData] = useState({
    day: {},
    week: {},
    month: {},
    year: {},
    totalSessions: 0,
    totalVisitors: 0,
    totalURLs: 0,
    totalUniqueURLsPerSession: 0,
    totalDesktopUsers: 0,
    totalMobileUsers: 0,
    countries: [],
  });

  const [mapKey, setMapKey] = useState(Date.now());

  useEffect(() => {
    const fetchData = async () => {
      try {
        const currentDate = new Date();
        const response = await axios.get(
          // `http://localhost:3001/api/visitor-data?_currentDate=${currentDate}`,
          `https://datamind.redmorph.com/api/visitor-data?_currentDate=${currentDate}`,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("rmxtoken"),
            },
          }
        );
        setVisitorData(response.data);
      } catch (error) {
        console.error("Error fetching visitor data:", error);
      }
    };

    // Fetch data immediately and then every 10 seconds
    fetchData();
    const intervalId = setInterval(fetchData, 10000); // 10 seconds

    // Cleanup function to clear the interval
    return () => clearInterval(intervalId);
  }, []);

  return (
    <div>
      <BaseComponent />
      <Navbar />
      <div className="row">
        <h4>Today's Stats</h4>
        <VisitorCard
          title="Visitors"
          count={visitorData.day.visitors || 0}
          icon="ri-calendar-2-line"
        />
        <VisitorCard
          title="Unique Visitors"
          count={visitorData.day.uniqueVisitors || 0}
          icon="ri-calendar-2-line"
        />
        <VisitorCard
          title="Total Search"
          count={visitorData.day.totalTechnicalSearch || 0}
          icon="ri-calendar-2-line"
        />
        <VisitorCard
          title="Unique Search"
          count={visitorData.day.uniqueTechnicalSearch || 0}
          icon="ri-calendar-2-line"
        />
        <VisitorCard
          title="Mobile / Desktop Users"
          count={`${visitorData.day.mobileUsers || 0} / ${
            visitorData.day.desktopUsers || 0
          }`}
          icon="ri-calendar-2-line"
        />
        <VisitorCard
          title="Unique Countries"
          count={visitorData.day.uniqueCountries || 0}
          icon="ri-calendar-2-line"
        />
      </div>
      <div className="row">
        <h4>Month's Stats</h4>
        <VisitorCard
          title="Visitors"
          count={visitorData.month.visitors || 0}
          icon="ri-calendar-2-line"
        />
        <VisitorCard
          title="Unique Visitors"
          count={visitorData.month.uniqueVisitors || 0}
          icon="ri-calendar-2-line"
        />
        <VisitorCard
          title="Total Search"
          count={visitorData.month.totalTechnicalSearch || 0}
          icon="ri-calendar-2-line"
        />
        <VisitorCard
          title="Unique Search"
          count={visitorData.month.uniqueTechnicalSearch || 0}
          icon="ri-calendar-2-line"
        />
        <VisitorCard
          title="Mobile / Desktop Users"
          count={`${visitorData.month.mobileUsers || 0} / ${
            visitorData.month.desktopUsers || 0
          }`}
          icon="ri-calendar-2-line"
        />

        <VisitorCard
          title="Unique Countries"
          count={visitorData.month.uniqueCountries || 0}
          icon="ri-calendar-2-line"
        />
      </div>
      <div className="row">
        <h4>Year's Stats</h4>
        <VisitorCard
          title="Visitors"
          count={visitorData.year.visitors || 0}
          icon="ri-calendar-2-line"
        />
        <VisitorCard
          title="Unique Visitors"
          count={visitorData.year.uniqueVisitors || 0}
          icon="ri-calendar-2-line"
        />
        <VisitorCard
          title="Total Search"
          count={visitorData.year.totalTechnicalSearch || 0}
          icon="ri-calendar-2-line"
        />
        <VisitorCard
          title="Unique Search"
          count={visitorData.year.uniqueTechnicalSearch || 0}
          icon="ri-calendar-2-line"
        />
        <VisitorCard
          title="Mobile / Desktop Users"
          count={`${visitorData.year.mobileUsers || 0} / ${
            visitorData.year.desktopUsers || 0
          }`}
          icon="ri-calendar-2-line"
        />
        <VisitorCard
          title="Unique Countries"
          count={visitorData.year.uniqueCountries || 0}
          icon="ri-calendar-2-line"
        />
      </div>
      {/* <div className="row">
        <h4>Other Stats</h4>
        <VisitorCard
          title="Total Sessions"
          count={visitorData.totalSessions}
          icon="ri-user-line"
        />
        <VisitorCard
          title="Total Visitors"
          count={visitorData.totalVisitors}
          icon="ri-user-line"
        />
        <VisitorCard
          title="Total URLs"
          count={visitorData.totalURLs}
          icon="ri-globe-line"
        />
        <VisitorCard
          title="Total Unique URLs per Session"
          count={visitorData.totalUniqueURLsPerSession}
          icon="ri-globe-line"
        />
        <VisitorCard
          title="Total Desktop Users"
          count={visitorData.totalDesktopUsers}
          icon="ri-laptop-line"
        />
        <VisitorCard
          title="Total Mobile Users"
          count={visitorData.totalMobileUsers}
          icon="ri-mobile-line"
        />
      </div> */}
      <div className="row">
        <MapCard countries={visitorData.countries} key={mapKey} />
      </div>
      <Footer />
    </div>
  );
};

export default Home;
