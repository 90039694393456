import React from "react";

const Footer = () => {
  return (
    <footer className="footer text-center main-footer fixed-bottom">
      {/* Copyright */}
      <div className="text-center pb-2">
        <p className="my-0">
          <small>
            © 2019-2024 All rights reserved by{" "}
            <a href="https://redmorph.com/">Redmorph.Inc</a>
          </small>
        </p>
      </div>
      {/* Copyright */}
    </footer>
  );
};

export default Footer;
